<template>
    <div class="about-body">
        <div v-if="info" class="about-body__container">
            <h1>Контакты</h1>
            <section v-if="info.text">
                <div class="anketa" v-html="info.text"></div>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactsPage",
        props: ["info"]
    }
</script>

<style scoped lang="scss">
    .about-body {
        flex: 1 1 auto;
        margin-bottom: 16px;
        text-align: left;
        @media screen and (min-width: 961px) {
            padding: 0;
        }

        &__container {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
        }
    }
</style>