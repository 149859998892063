<template>
    <contacts-page :info="getCONTACTS" />
</template>

<script>
    import ContactsPage from "../../../components/pages/cabinet/about/ContactsPage";
    import {mapGetters} from "vuex";
    export default {
        name: "Contacts",
        components: {ContactsPage},
        computed: {
            ...mapGetters("AboutModule", ["getCONTACTS"])
        }
    }
</script>